import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput } from 'react-native-web';
import { server_var, themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';
import axios from 'axios';

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.05, textAlign: 'left', headerTitle: 'ID' },
  { id: 2, field1: 'usedDateTime', flex: 0.15, textAlign: 'left', headerTitle: 'วันที่ใช้', controlType: 'datetime' },
  { id: 3, field1: 'users', field2: 'displayName', flex: 0.20, textAlign: 'left', headerTitle: 'ชื่อลูกค้า' },
  { id: 4, field1: 'shops', field2: 'shopName', flex: 0.10, textAlign: 'left', headerTitle: 'สาขา' },
  { id: 5, fieldSpecial: 'special2', flex: 0.25, textAlign: 'left', headerTitle: 'คูปอง' },
  { id: 6, field1: 'refValue2', flex: 0.15, textAlign: 'left', headerTitle: 'reference' },
  { id: 7, fieldSpecial: 'special1', flex: 0.10, textAlign: 'right', headerTitle: 'รวมส่วนลด' },
];

const DETAIL_DEFINE = [
  { id: 1, fieldName: 'id', displayName: 'ID', newValue: 0 },
];

const QUERY_NAME = 'couponTracks';
const QUERY_CON = {};

export const TrackCoupon = () => {
  const { globalState } = useContext(store);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [queryParams, setQueryParams] = useState(null);
  const [shopList, setShopList] = useState([]);

  useEffect(() => {
    loadShopList();
  }, [])

  const loadShopList = async () => {
    const res = await globalState.client.service('shops').find({ query: { $select: ['id', 'shopName'], $limit: 50 } });
    setShopList(res.data.map(item => ({ key: item.id, label: item.shopName })));
  }

  const downloadTxtFile = async () => {
    const res = await axios.post(`${server_var.server_api}/extractTrackCoupons`, queryParams)
    if (res?.data?.csv) {
      console.log('texts', res.data.csv);
      const file = new Blob([res.data.csv], { type: 'text/plain' });
      const element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = "trackcoupon" + Date.now() + ".csv";
      document.body.appendChild(element);
      element.click();
    } else if (res.data.message) {
      alert(res.data.message);
    } else {
      alert('มีข้อผิดพลาดเกิดขึ้น')
    }
  }

  const onChangeQuery = (queryParams) => {
    setQueryParams(queryParams);
  }

  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={24} style={{ height: '100vh', padding: 5 }}>
        <ViewList
          onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE}
          queryName={QUERY_NAME} queryCon={QUERY_CON}
          onChangeQuery={onChangeQuery}
          viewListOption={{
            hideNew: true,
            topControlList: [
              { id: 1, title: 'Export', type: 'button', onPress: () => downloadTxtFile() },
              // { id: 2, title: 'Search', type: 'multi_column_search', searchField: ['$users.displayName$', '$shops.shopName$'], placeholder: 'ค้นหาตามชื่อลูกค้า,ชื่อร้าน', textInputStyle: { width: 300 } },
              {
                id: 3, title: 'Search', type: 'dropdown', searchField: 'shopId', list: shopList,
              },
              { id: 4, title: '', type: 'daterange', searchField: 'usedDateTime', placeholder: ['วันเริ่มใช้', 'วันสิ้นสุดการใช้'] },
            ]
          }}
        />
      </Col>
      {/* <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail selectIndex={selectIndex} detailDefine={DETAIL_DEFINE} queryName={QUERY_NAME} onSavePress={(id) => setSelectIndex(id)} />
      </Col> */}
    </Row>
  )
}

