import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput } from 'react-native-web';
import { themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.1, textAlign: 'left', headerTitle: 'ID' },
  { id: 2, field1: 'title', flex: 0.4, textAlign: 'left', headerTitle: 'ชื่อสินค้า' },
  { id: 3, field1: 'startDate', flex: 0.3, textAlign: 'right', headerTitle: 'วันเริ่ม', controlType: 'datetime' },
  { id: 4, field1: 'endDate', flex: 0.3, textAlign: 'right', headerTitle: 'วันสิ้นสุด', controlType: 'datetime' },
];

const DETAIL_DEFINE = [
  {
    id: 1, fieldName: 'id', displayName: 'ID', newValue: 0, controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 2, fieldName: 'title', displayName: 'ชื่อสินค้า', newValue: '' },
  { id: 3, fieldName: 'priceList', displayName: 'ราคาเต็ม', newValue: '' },
  { id: 4, fieldName: 'priceNet', displayName: 'ราคา Net', newValue: '' },
  { id: 5, fieldName: 'feedLink', displayName: 'Link', newValue: '' },
  { id: 6, fieldName: 'feedScore', displayName: 'Sort Score', newValue: 0 },
  { id: 7, fieldName: 'startDate', displayName: 'วัน/เวลาเริ่ม', newValue: null, controlType: 'datetimeinput' },
  { id: 8, fieldName: 'endDate', displayName: 'วัน/เวลาสิ้นสุด', newValue: null, controlType: 'datetimeinput' },
  { id: 9, fieldName: 'feedPhoto1', displayName: 'รูป', newValue: '', controlType: 'photoupload', controlOption: { width: 200, height: 80 } },
];

const QUERY_NAME = 'feeds';
const QUERY_CON = { feedType: 'FlashSales' };
const NEW_VALUE = { feedType: 'FlashSales' };

export const FlashSale = () => {
  const [selectIndex, setSelectIndex] = useState(-1);

  const beforeSavePress = async (data) => {
    return { data: { ...data, feedPublic: 1 }, errorList: validateData(data) }
  }

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const validateData = (data) => {
    let tmpErrorList = [];
    for (const item of ['title', 'priceList', 'priceNet']) {
      if (!(data?.[item])) {
        tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้' }]
      }
    }
    for (const item of ['startDate', 'endDate']) {
      if (!(data?.[item])) {
        tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้ หรือวันเวลาไม่ถูกต้อง' }]
      }
    }
    if (tmpErrorList.length == 0) {
      for (const item of ['priceList', 'priceNet']) {
        if (!isNumber(data?.[item])) {
          tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ค่าไม่ถูกต้อง' }]
        }
      }
    }
    return tmpErrorList
  }
  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={12} style={{ height: '100vh', padding: 5 }}>
        <ViewList onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE} queryName={QUERY_NAME} queryCon={QUERY_CON} />
      </Col>
      <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail
          selectIndex={selectIndex} detailDefine={DETAIL_DEFINE} queryName={QUERY_NAME}
          newValue={NEW_VALUE} onSavePress={(id) => setSelectIndex(-1)}
          beforeSavePress={beforeSavePress}
        />
      </Col>
    </Row>
  )
}

