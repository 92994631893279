import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button } from 'antd';
import { View, Text } from 'react-native-web'
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { LaptopOutlined, NotificationOutlined, UserOutlined, LogoutOutlined, CopyrightOutlined, PicCenterOutlined, PicLeftOutlined, DollarOutlined, BankOutlined, AuditOutlined, SmileOutlined } from '@ant-design/icons';
import { Routes, Route, Navigate } from "react-router-dom";
import { store } from "../store";
import { themeColor } from "../config/servervar";
import { appLogout } from "../component/custom";

const { Header, Content, Sider } = Layout;

const menuList = ({ navigate, globalState, dispatch }) => {
  return [
    { id: 1, icon: SmileOutlined, label: 'Customer', path: '/customer', visible: true },
    { id: 2, icon: CopyrightOutlined, label: 'Coupon', path: '/coupon', visible: ['admin'].includes(globalState?.user?.roles) },
    { id: 3, icon: AuditOutlined, label: 'Track Coupon', path: '/trackcoupon', visible: ['admin'].includes(globalState?.user?.roles) },
    { id: 4, icon: PicCenterOutlined, label: 'Top Banner', path: '/topbanner', visible: ['admin', 'marketing'].includes(globalState?.user?.roles) },
    { id: 5, icon: PicLeftOutlined, label: 'Content Banner', path: '/contentbanner', visible: ['admin', 'marketing'].includes(globalState?.user?.roles) },
    { id: 6, icon: DollarOutlined, label: 'Flash Sale', path: '/flashsale', visible: ['admin', 'marketing'].includes(globalState?.user?.roles) },
    { id: 7, icon: BankOutlined, label: 'Shop', path: '/shop', visible: ['admin'].includes(globalState?.user?.roles) },
    { id: 8, icon: NotificationOutlined, label: 'Notification', path: '/notification', visible: ['admin', 'marketing'].includes(globalState?.user?.roles) },
    { id: 9, icon: UserOutlined, label: 'Staff', path: '/user', visible: ['admin'].includes(globalState?.user?.roles) },
    { id: 12, icon: LogoutOutlined, label: 'Logout', path: '/page3', onClick: () => appLogout(globalState, dispatch), visible: true }
  ].filter(item => item.visible).map(
    item => ({
      key: item.id.toString(),
      icon: React.createElement(item.icon),
      label: item.label,
      onClick: () => {
        if (item.onClick) {
          item.onClick()
        } else {
          navigate(item.path)
        }
      }
    }),
  );
}

export const ScreenLayout = () => {
  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(store);
  if (globalState?.user) {
    return (
      <Layout style={{ height: '100vh' }}>
        <Layout>
          <Sider width={200} style={{ background: themeColor.color1, display: 'flex', flexDirection: 'column' }}>
            <div style={{
              flex: 1, height: 100,
              alignSelf: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'
            }}>
              <div style={{ padding: 10, backgroundColor: 'white', borderRadius: 10, marginTop: 20 }}>
                <img src="/namping_logo.png" style={{ width: 70, height: 70 }} />
              </div>
            </div>
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['sub1']}
              style={{ borderRight: 0, backgroundColor: themeColor.color1, color: 'white', marginTop: 30 }}
              items={menuList({ navigate, globalState, dispatch })}
            />
          </Sider>
          <Layout style={{ padding: '0' }}>
            <Content
              style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  } else {
    return <Navigate to="/login" replace />
  }

}
