import React, { useContext, useState, useEffect, useRef } from "react";
import io from 'socket.io-client'
import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import authentication from '@feathersjs/authentication-client'
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput, Image, Platform, Modal, Dimensions } from 'react-native-web';
import { themeColor, server_var } from "../config/servervar";
import EventEmitter from "../component/EventEmitter";
import { uploadPhoto } from '../component/custom';
import { CloseOutlined, PlusCircleOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { parse, format, parseISO, isValid, formatISO } from 'date-fns';
import { subYears, addYears } from 'date-fns';
import { Dropdown, Space } from 'antd';
import { DownOutlined, CheckCircleOutlined, BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { useDebounce } from "../component/custom";
import { TopControlList } from "./listdetail_top";
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCheckboxBlankCircleOutline, mdiNoteTextOutline } from '@mdi/js';


const TRANSLATE_COLUMN = [
  { id: 1, sortFieldName: 'user.displayName', rawFieldName: 'userId' },
  { id: 2, sortFieldName: 'shop.shopThName', rawFieldName: 'shopId' },
  { id: 3, sortFieldName: 'pharmacist.displayName', rawFieldName: 'pharmaId' },
];

export function ViewList({
  onPress, listDefine, queryName, queryCon, viewListOption, onChangeQuery
}) {
  const { globalState } = useContext(store);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [sortFieldName, setSortFieldName] = useState('id');
  const [sortDirection, setSortDirection] = useState(-1);
  const [searchList, setSearchList] = useState([]);

  const onPressHeader = (paramBack) => {
    if (paramBack.field2) {
      setSortFieldName(`${paramBack.field1}.${paramBack.field2}`);
    } else {
      setSortFieldName(paramBack.field1);
    }

    if (sortDirection == -1) {
      setSortDirection(1)
    } else {
      setSortDirection(-1);
    }
  }

  useEffect(() => {
    loadDataList();
  }, [])

  useEffect(() => {
    loadDataList(0);
  }, [sortFieldName, sortDirection])

  useEffect(() => {
    loadDataList(0);
    if (onChangeQuery) {
      onChangeQuery(getQuery(0))
    }
  }, [searchList])

  useEffect(() => {
    EventEmitter.addListener('List:refresh', refreshList);
    return () => {
      EventEmitter.removeListener('List:refresh', refreshList);
    };
  }, [sortFieldName, sortDirection])

  const refreshList = () => {
    loadDataList(0);
  }

  const getQuery = (pSkip) => {
    let rawFieldName = sortFieldName;
    const tmp1 = TRANSLATE_COLUMN?.find(item2 => item2.sortFieldName == sortFieldName)?.rawFieldName;
    if (tmp1) {
      rawFieldName = TRANSLATE_COLUMN?.find(item2 => item2.sortFieldName == sortFieldName)?.rawFieldName;
    }

    let tmpSearchQuery;
    if (searchList?.length > 0) {
      tmpSearchQuery = searchList.reduce((acc, item2) => {
        const { key, ...rest } = item2;
        return { ...acc, ...rest }
      }, {});
    }

    const tmpQuery = {
      ...queryCon,
      $skip: pSkip ?? skip,
      $limit: 40,
      ...(tmpSearchQuery ? tmpSearchQuery : null),
      $sort: rawFieldName ? { [rawFieldName]: sortDirection } : { id: -1 },
    };
    return tmpQuery;
  }

  const loadDataList = async (pSkip) => {
    if (!loading) {
      setLoading(true);

      const query = getQuery(pSkip);
      const res = await globalState.client.service(queryName).find({ query });
      // alert("Hello")
      if ((pSkip ?? skip) == 0) {
        setDataList(res.data)
      } else {
        setDataList(prev => [...prev, ...res.data]);
      }
      setSkip((res.skip + res.limit));
      setLoading(false)
    }
  }

  return (
    <View style={{ paddingHorizontal: 10 }}>
      <TopControlList viewListOption={viewListOption} onPress={onPress}
        searchList={searchList} setSearchList={setSearchList}
      />
      <View style={{ marginTop: 10, backgroundColor: 'lightgray', borderRadius: 5 }}>
        <ViewListItem show="header" listDefine={listDefine} onPress={onPressHeader} sortFieldName={sortFieldName} sortDirection={sortDirection} />
      </View>
      <FlatList
        keyExtractor={(item) => item.id.toString()}
        style={{ marginTop: 0, height: '88vh' }}
        data={dataList}
        onEndReached={(xxx) => loadDataList()}
        onEndReachedThreshold={0.5}
        onRefresh={() => loadDataList(0)}
        refreshing={(loading && skip == 0)}
        renderItem={({ item }) => <ViewListItem item={item} onPress={onPress} show="item" listDefine={listDefine} />}
      />
    </View>
  )
}

function ViewListItem({ item, onPress, show, listDefine, sortFieldName, sortDirection }) {
  if (show == 'header') {
    return (
      <View style={{
        flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10,
        paddingHorizontal: 5,
      }}>
        {listDefine.map((item2) => (
          <Pressable
            key={item2.id.toString()} style={{ flex: item2.flex }}
            onPress={() => onPress(item2)}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: item2?.textAlign == 'right' ? 'flex-end' : 'flex-start' }}>
              <Text style={{ textAlign: item2.textAlign, marginRight: 5 }}>{item2.headerTitle}</Text>
              {(sortFieldName == [item2.field1, item2.field2].filter(item5 => item5).join('.')) && sortDirection == 1 && <CaretUpOutlined />}
              {(sortFieldName == [item2.field1, item2.field2].filter(item5 => item5).join('.')) && sortDirection == -1 && <CaretDownOutlined />}
            </View>
          </Pressable>
        ))}
      </View>
    )
  }

  return (
    <Pressable onPress={() => onPress(item.id)}>
      {({ hovered }) => (
        <View style={{
          flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 10,
          backgroundColor: hovered ? 'lightgray' : 'transparent', paddingHorizontal: 5,
        }}>
          {listDefine.map((item2) => (
            <View key={item2.id.toString()} style={{ flex: item2.flex }}>
              <ViewListItemText item2={item2} item={item} />
            </View>
          ))}
        </View>
      )}
    </Pressable>
  )
}

function ViewListItemText({ item2, item }) {
  let fieldValue;
  if (item2.fieldSpecial) {
    if (item2.fieldSpecial == 'special1') {
      fieldValue = JSON.stringify(item.couponUsers.reduce((acc, item) => (acc + item?.coupons?.couponValue), 0));
    }
    if (item2.fieldSpecial == 'special2') {
      fieldValue = (item.couponUsers.map(item => (item?.coupons?.title))).join(',')
      // fieldValue = item.couponUsers.reduce((acc, item) => (`${acc??','} ${item?.coupons?.title}`).trim(), null);
    }
  } else if (item2.field1 && item2.field2 && item2.field3) {
    fieldValue = item?.[item2.field1]?.[item2.field2]?.[item2.field3];
  } else if (item2.field1 && item2.field2) {
    fieldValue = item?.[item2.field1]?.[item2.field2];
  } else {
    fieldValue = item?.[item2.field1];
  }

  if (item2?.controlType == 'datetime' && (fieldValue)) {
    const tmpDate = addYears(new Date(fieldValue), 543);
    const tmpDateString = isValid(tmpDate) ? format(tmpDate, 'dd/MM/yyyy HH:mm') : fieldValue;
    return (
      <View>
        <Text style={{ textAlign: item2.textAlign }}>{tmpDateString}</Text>
      </View>
    )
  }
  if (item2?.controlType == 'mapping' && item2?.controlOption?.list) {
    const tmpString = item2?.controlOption?.list?.find(item5 => (item5.value == fieldValue))?.title
    return (
      <View>
        <Text style={{ textAlign: item2.textAlign }}>{tmpString}</Text>
      </View>
    )
  }

  return (
    <View>
      <Text style={{ textAlign: item2.textAlign }}>{fieldValue}</Text>
    </View>
  )
}

export function ViewDetail({
  selectIndex, detailDefine, queryName, queryCon, newValue, onSavePress,
  viewDetailOption, beforeSavePress = (data) => ({ data, errorList: [] })
}) {
  const { globalState } = useContext(store);
  const [data, setData] = useState();
  const [errorList, setErrorList] = useState([]);

  useEffect(() => {
    loadData(selectIndex)
    setErrorList([])
  }, [selectIndex])

  const loadData = async (id) => {
    // console.log('id', id);
    if (id == -1) {
      setData(null)
      return
    }
    if (id == 0) {
      setData(detailDefine.reduce((acc, item) => ({ ...acc, [item.fieldName]: item.newValue }), newValue));
      return;
    }
    let res;
    if (queryCon) {
      res = await globalState.client.service(queryName).get(id, { query: queryCon });
    } else {
      res = await globalState.client.service(queryName).get(id);
    }
    // console.log('coupon', res);
    setData(res);
  }

  if (selectIndex == -1) {
    return <View />
  }

  let tmpData
  if (data) {
    tmpData = detailDefine.reduce((acc, item5) => [...acc, [item5.fieldName, data[item5.fieldName]]], [])
  }

  const onChangeData = (detailItem) => {
    setData(prev => ({ ...prev, ...detailItem }));
  }

  const saveData = async () => {
    if (window.confirm('ต้องการบันทึกข้อมูล')) {
      console.log('before save data point1');
      setErrorList([]);
      const { 'data': tmpData, 'errorList': tmpErrorList } = await beforeSavePress(data);
      console.log('before save data point2');
      if (tmpErrorList && tmpErrorList.length > 0) {
        setErrorList(tmpErrorList);
        return;
      }
      if (tmpData.id == 0) {
        const res = await globalState.client.service(queryName).create(tmpData);
        setData(res);
        onSavePress(res.id);
        EventEmitter.notify('List:refresh')
        return;
      }
      if (tmpData.id != 0) {
        const { id, createdAt, updatedAt, ...rest } = tmpData
        const res = await globalState.client.service(queryName).patch(tmpData.id, rest);
        setData(res);
        onSavePress(res.id);
        EventEmitter.notify('List:refresh')
        return
      }
    }
  }

  const deleteData = async () => {
    if (window.confirm('ต้องการลบข้อมูล')) {
      if (data.id != 0) {
        await globalState.client.service(queryName).remove(data.id);
        setData(null);
        EventEmitter.notify('List:refresh')
      }
    }
  }

  if (data == null) {
    return <View />
  }

  return (
    <View style={{ paddingHorizontal: 10 }}>
      <Text style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10 }}></Text>
      {/* <Text>{JSON.stringify(data, '', 2)}</Text> */}
      {/* <Text>{selectIndex}</Text>
      <Text>{JSON.stringify(errorList)}</Text> */}
      <ScrollView style={{ paddingHorizontal: 10, height: '80vh' }}>
        {tmpData?.map(item2 => (
          <ViewDetailItem key={item2[0]} item={item2} detailDefine={detailDefine} onChangeData={onChangeData} data={tmpData} errorList={errorList} />
        ))}
      </ScrollView>
      <View style={{ flexDirection: 'row', marginTop: 20, alignItems: 'center', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row' }}>
          {[
            { id: 1, title: 'Save', backgroundColor: themeColor.color1, textColor: 'white', onPress: () => saveData() },
            { id: 2, title: 'Cancel', backgroundColor: 'lightgray', textColor: 'black', onPress: () => loadData(selectIndex) },
          ].map(item2 => (
            <Pressable key={item2.id.toString()} onPress={item2.onPress}>
              {({ hovered }) => (
                <View style={{
                  width: 120, height: 40, borderRadius: 10, justifyContent: 'center', alignItems: 'center',
                  backgroundColor: item2.backgroundColor, opacity: hovered ? 0.8 : 1, marginHorizontal: 10
                }}>
                  <Text style={{ color: item2.textColor }}>{item2.title}</Text>
                </View>
              )}
            </Pressable>
          ))}
        </View>
        {!viewDetailOption?.hideDelete && (
          <Pressable onPress={() => deleteData()}>
            {({ hovered }) => (
              <View style={{
                width: 100, height: 40, borderRadius: 10, justifyContent: 'center', alignItems: 'center',
                backgroundColor: themeColor.color4, opacity: hovered ? 0.8 : 1
              }}>
                <Text style={{ color: 'white' }}>Delete</Text>
              </View>
            )}
          </Pressable>
        )}

      </View>
      {/* <Text>{JSON.stringify(data, '', 2)}</Text> */}
    </View>
  )
}

function ViewDetailItem({ item, detailDefine, onChangeData, data, errorList }) {
  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption;
  if (['code01'].includes(controlOption?.visible) && data.find(item6 => item6[0] == 'couponType')?.[1] != 'NewShopOpen') {
    return <View />
  }
  if (['code02'].includes(controlOption?.visible) && data.find(item6 => item6[0] == 'roles')?.[1] != 'cashier') {
    return <View />
  }
  if (['code03'].includes(controlOption?.visible) && data.find(item6 => item6[0] == 'couponType')?.[1] != 'Gimmick') {
    return <View />
  }

  if (!detailDefine.filter(item2 => item2.hide).map(item2 => item2.fieldName).includes(item[0])) {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 5 }}>
        <View style={{ flex: 0.3 }}>
          <Text>{(detailDefine.find(item2 => item2.fieldName == item[0])?.displayName) ?? item[0]}:</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <ViewDetailItemInput item={item} onChangeData={onChangeData} detailDefine={detailDefine} data={data} errorList={errorList} />
        </View>
      </View>
    )
  }
}

function ViewDetailItemInput(props) {
  const { item, onChangeData, detailDefine, data, errorList } = props;
  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption
  const errorItem = errorList?.find(item7 => item7.fieldName == item[0]);

  if (detailDefine.find(item4 => item4.fieldName == item[0]).controlType == 'password') {
    return <ViewDetailItemPassword {...props} errorItem={errorItem} />
  }

  if (detailDefine.find(item4 => item4.fieldName == item[0]).controlType == 'photoupload') {
    return <ViewDetailItemInputPhotoUpload {...props} />
  }

  if (detailDefine.find(item4 => item4.fieldName == item[0]).controlType == 'dropdown') {
    return <ViewDetailItemInputDropDown {...props} errorItem={errorItem} />
  }

  if (detailDefine.find(item4 => item4.fieldName == item[0]).controlType == 'radio') {
    return <ViewDetailItemInputRadio {...props} errorItem={errorItem} />
  }

  if (detailDefine.find(item4 => item4.fieldName == item[0]).controlType == 'datetimeinput') {
    return <ViewDetailItemInputDateTimeInput {...props} errorItem={errorItem} />
  }

  if (detailDefine.find(item4 => item4.fieldName == item[0]).controlType == 'dateinput') {
    return <ViewDetailItemInputDateInput {...props} errorItem={errorItem} />
  }


  if (detailDefine.find(item4 => item4.fieldName == item[0]).controlType == 'component') {
    return detailDefine.find(item4 => item4.controlType == 'component')?.component(props)
  }

  if (typeof (item[1]) == 'string')
    return (
      <View>
        <TextInput
          style={[
            { backgroundColor: 'white', height: 40, borderRadius: 5, paddingHorizontal: 5 },
            controlOption?.textInputStyle, errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null
          ]}
          value={item[1]}
          onChangeText={(text) => onChangeData({ [item[0]]: text })}
          {...controlOption?.textInputProp}
        />
        {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
      </View>
    )
  if (typeof (item[1]) == 'object') {
    return (
      <View>
        <TextInput
          style={[
            { backgroundColor: 'white', height: 40, borderRadius: 5, paddingHorizontal: 5 },
            controlOption?.textInputStyle, errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null
          ]}
          value={item[1] ? JSON.stringify(item[1]) : ''}
          onChangeText={(text) => onChangeData({ [item[0]]: text })}
          {...controlOption?.textInputProp}
        />
        {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
      </View>
    )
  }
  if (typeof (item[1]) == 'number') {
    return (
      <View>
        <TextInput
          style={[
            { backgroundColor: 'white', height: 40, borderRadius: 5, paddingHorizontal: 5 },
            controlOption?.textInputStyle,
            errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null
          ]}
          value={item[1].toString()}
          onChangeText={(text) => onChangeData({ [item[0]]: text.toString() })}
          {...controlOption?.textInputProp}
        />
        {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
      </View>
    )
  }
}

function ViewDetailItemPassword({ item, onChangeData, detailDefine, errorItem }) {
  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption;
  return (
    <View>
      <TextInput
        style={[
          { backgroundColor: 'white', height: 40, borderRadius: 5, paddingHorizontal: 5 },
          controlOption?.textInputStyle, errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null
        ]}
        value={item[1]}
        secureTextEntry={true}
        onChangeText={(text) => onChangeData({ [item[0]]: text })}
        {...controlOption?.textInputProp}
      />
      {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
    </View>
  )
}

function ViewDetailItemInputDropDown({ item, onChangeData, detailDefine, errorItem }) {
  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption;
  const items = controlOption?.list.map(item5 => ({ ...item5, key: item5.value, label: item5.title }))
  const onClick = ({ key }) => {
    onChangeData({ [item[0]]: key });
  }
  return (
    <Dropdown menu={{ items, onClick }} trigger={['click']}>
      <a onClick={(e) => e.preventDefault()}>
        <View>
          <View style={[{
            backgroundColor: 'white', flexDirection: 'row',
            height: 40,
            borderRadius: 5, paddingHorizontal: 5, alignItems: 'center', justifyContent: 'space-between'
          }, errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null]}>
            <Text>{controlOption?.list?.find(item5 => item5.value == item[1])?.title}</Text>
            <CaretDownOutlined />
          </View>
          {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
        </View>
      </a>
    </Dropdown>
  )
}

function ViewDetailItemInputRadio({ item, onChangeData, detailDefine, errorItem }) {
  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption;
  const items = controlOption?.list.map(item5 => ({ ...item5, key: item5.value, label: item5.title }))
  return (
    <View>
      <View style={[{
        backgroundColor: 'white', flexDirection: 'row',
        height: 40,
        borderRadius: 5, paddingHorizontal: 5, alignItems: 'center', justifyContent: 'space-between'
      }, errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null]}>
        <View style={{ flexDirection: 'row' }}>
          {items.map(item5 => (
            <Pressable key={item5.id.toString()} style={{ flexDirection: 'row', alignItems: 'center', marginRight: 25 }}
              onPress={() => onChangeData({ [item[0]]: item5.value })}
            >
              <Icon path={(item[1] == item5.value) ? mdiCheckCircle : mdiCheckboxBlankCircleOutline}
                size={24 / 24}
                color={'gray'} />
              <Text style={{ marginLeft: 5 }}>{item5.label}</Text>
            </Pressable>))}
        </View>
      </View>
      {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
    </View>
  )
}

function ViewDetailItemInputDropDown2({ item, onChangeData, detailDefine }) {
  const [ddVisible, setDdVisible] = useState(false);
  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption
  return (
    <View>
      <Pressable onPress={() => setDdVisible(true)}>
        <View style={{
          backgroundColor: 'white', flexDirection: 'row',
          height: 40,
          borderRadius: 5, paddingHorizontal: 5, alignItems: 'center', justifyContent: 'space-between'
        }}>
          <Text>{controlOption?.list?.find(item5 => item5.value == item[1])?.title}</Text>
          <CaretDownOutlined />
        </View>
      </Pressable>
      <Modal visible={ddVisible} transparent={true}>
        <View style={{
          position: 'absolute', top: 0, bottom: 0, left: 0, right: 0,
          backgroundColor: 'rgba(0,0,0,0.4)',
          justifyContent: 'center', alignItems: 'center'
        }}>
          <View style={{
            backgroundColor: 'white', width: Dimensions.get('window').width / 4,
            paddingHorizontal: 20, paddingVertical: 20, borderRadius: 10
          }}>
            {controlOption?.title ? <Text>{controlOption?.title}</Text> : <View />}
            {controlOption?.list?.map(item2 => (
              <Pressable
                key={item2.id.toString()} onPress={() => {
                  onChangeData({ [item[0]]: item2.value });
                  setDdVisible(false)
                }}
              >
                {({ hovered }) =>
                (
                  <View style={{ paddingHorizontal: 10, paddingVertical: 10, backgroundColor: hovered ? 'lightgray' : 'white' }}>
                    <Text >{item2.title}</Text>
                  </View>
                )
                }

              </Pressable>
            ))}
            <View style={{ position: 'absolute', top: 0, right: 0 }}>
              <Pressable onPress={() => setDdVisible(false)}>
                <View style={{
                  width: 24, height: 24, justifyContent: 'center', alignItems: 'center', backgroundColor: 'lightgray', borderRadius: 12
                }}>
                  <CloseOutlined style={{ fontSize: 12 }} />
                </View>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}

function getDateTimeOutput(prev, text) {
  let tmpString = text.split('');
  if (tmpString.length > 16) return prev;

  try {
    if (prev?.length < text?.length) {
      if (text.length == 2) {
        tmpString.splice(2, 0, '/')
      } else if (text.length >= 2 && tmpString.length >= 3 && countStr(text, '/') < 1) {
        tmpString.splice(2, 0, '/')
      } else if (text.length == 5) {
        tmpString.splice(5, 0, '/')
      } else if (text.length >= 4 && tmpString.length >= 6 && countStr(text, '/') < 2) {
        tmpString.splice(5, 0, '/')
      } else if (text.length == 10 && countStr(text, ' ') < 1) {
        tmpString.splice(10, 0, ' ')
      } else if (text.length >= 9 && tmpString.length >= 10 && countStr(text, ' ') < 1) {
        tmpString.splice(10, 0, ' ')
      } else if (text.length >= 13 && countStr(text, ':') < 1) {
        tmpString.splice(13, 0, ':')
      }
    } else if (prev?.length > text?.length) {
      if (text.length == 3) {
        tmpString.splice(2, 1);
      }
      if (text.length == 6) {
        tmpString.splice(5, 1);
      }
      if (text.length == 11) {
        tmpString.splice(10, 1);
      }
      if (text.length == 14 && (countStr(text, ':') < 1)) {
        tmpString.splice(13, 1);
      }
    }
  } catch (e) {
    console.log('error', e);
    return prev;
  }
  return tmpString.join('');
}

function getDateOutput(prev, text) {
  let tmpString = text.split('');
  if (tmpString.length > 10) return prev;

  try {
    if (prev?.length < text?.length) {
      if (text.length == 2) {
        tmpString.splice(2, 0, '/')
      } else if (text.length >= 2 && tmpString.length >= 3 && countStr(text, '/') < 1) {
        tmpString.splice(2, 0, '/')
      } else if (text.length == 5) {
        tmpString.splice(5, 0, '/')
      } else if (text.length >= 4 && tmpString.length >= 6 && countStr(text, '/') < 2) {
        tmpString.splice(5, 0, '/')
      }
    } else if (prev?.length > text?.length) {
      if (text.length == 3) {
        tmpString.splice(2, 1);
      }
      if (text.length == 6) {
        tmpString.splice(5, 1);
      }
    }
  } catch (e) {
    console.log('error', e);
    return prev;
  }
  return tmpString.join('');
}

function countStr(sourceStr, searchStr) {
  return sourceStr.split(searchStr).length - 1
}

function ViewDetailItemInputDateTimeInput({ item, onChangeData, detailDefine, errorItem }) {
  const [dateTimeStr, setDateTimeStr] = useState();
  const [choice, setChoice] = useState(0);

  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption

  useEffect(() => {
    const tmpDateTime = new Date(item[1]);
    if (item[1] == null) {
      setDateTimeStr('');
      return;
    }
    if (!isValid(tmpDateTime)) {
      setDateTimeStr('')
      return
    }
    if (isValid(tmpDateTime)) {
      setDateTimeStr(format(addYears(tmpDateTime, 543), 'dd/MM/yyyy HH:mm'));
    }
  }, [item[1]])

  useEffect(() => {
    const tmpDateTime = parse(dateTimeStr, 'dd/MM/yyyy HH:mm', new Date());
    const tmpDateTime2 = subYears(tmpDateTime, 543);
    if (isValid(tmpDateTime2) && dateTimeStr.length == 16) {
      onChangeData({ [item[0]]: formatISO(tmpDateTime2) })
    }
  }, [dateTimeStr])

  return (
    <View>
      <TextInput
        style={[{ backgroundColor: 'white', height: 40, borderRadius: 5, paddingHorizontal: 5 },
        errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null
        ]}
        value={dateTimeStr}
        onChangeText={(text) => {
          setDateTimeStr(prev => getDateTimeOutput(prev, text))
        }}
      />
      {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
    </View>
  )
}

function ViewDetailItemInputDateInput({ item, onChangeData, detailDefine, errorItem }) {
  const [dateTimeStr, setDateTimeStr] = useState();

  useEffect(() => {
    const tmpDateTime = new Date(item[1]);
    if (item[1] == null) {
      setDateTimeStr('')
      return
    }
    if (!isValid(tmpDateTime)) {
      setDateTimeStr('')
      return
    }
    if (isValid(tmpDateTime)) {
      setDateTimeStr(format(addYears(tmpDateTime, 543), 'dd/MM/yyyy'));
    }
  }, [item[1]])

  useEffect(() => {
    const tmpDateTime = parse(dateTimeStr, 'dd/MM/yyyy', new Date());
    const tmpDateTime2 = subYears(tmpDateTime, 543)
    if (isValid(tmpDateTime2) && dateTimeStr.length == 10) {
      onChangeData({ [item[0]]: format(tmpDateTime2, 'yyyy-MM-dd') })
    }
  }, [dateTimeStr])

  return (
    <View>
      <TextInput
        style={[{ backgroundColor: 'white', height: 40, borderRadius: 5, paddingHorizontal: 5 },
        errorItem?.errorFlag ? { borderColor: 'red', borderWidth: 1 } : null
        ]}
        value={dateTimeStr}
        onChangeText={(text) => {
          setDateTimeStr(prev => getDateOutput(prev, text))
        }}
      />
      {errorItem?.errorFlag && <Text style={{ color: 'red' }}>{errorItem?.errorMsg}</Text>}
    </View>
  )
}


function ViewDetailItemInputPhotoUpload({ item, onChangeData, detailDefine, errorItem }) {
  const fileRef1 = useRef();
  let imageUri = item[1];

  const controlOption = detailDefine.find(item4 => item4.fieldName == item[0])?.controlOption

  if (imageUri && !imageUri.includes('http')) {
    imageUri = `${server_var.server_api}/${imageUri}`;
  }
  const uploadPhoto1 = async (e) => {
    uploadPhoto2(e.target.files)
  }

  const uploadPhoto2 = async (targetFiles) => {
    let images;
    try {
      images = await uploadPhoto('banner', false, 'photolib', targetFiles);
    } catch { }
    console.log('images!!' + JSON.stringify(images));
    if (!images) return;
    if (!Array.isArray(images)) {
      images = [images];
    }
    for (const item5 of images) {
      onChangeData({ [item[0]]: `${server_var.server_api}/image/tmpUpload/${item5.filename}` })
    }
  }

  return (
    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
      <View>
        {imageUri ?
          (<View>
            <Image source={{ uri: imageUri }} resizeMode="contain" style={{ width: controlOption?.width ?? 80, height: controlOption?.height ?? 80 }} />
          </View>
          )
          :
          (<Pressable onPress={() => fileRef1.current.click()}>
            <View style={{
              width: 80, height: 80, backgroundColor: 'lightgray', borderRadius: 10,
              justifyContent: 'center', alignItems: 'center'
            }}>
              <PlusCircleOutlined style={{ fontSize: 25, color: 'gray' }} />
              <Text style={{ marginTop: 3, color: 'gray', fontSize: 12 }}>เพิ่มรูป</Text>
            </View>
          </Pressable>)}
        {imageUri ? (
          <Pressable onPress={() => onChangeData({ [item[0]]: '' })}
            style={{
              position: 'absolute', top: -10, right: -10, backgroundColor: 'red',
              width: 18, height: 18, borderRadius: 9, justifyContent: 'center', alignItems: 'center'
            }}
          >
            <CloseOutlined style={{ color: 'white', fontSize: 12 }} />
          </Pressable>
        ) : <View />}

      </View>

      {
        Platform.OS == 'web' && (
          <div style={{ display: 'flex' }}>
            <input type="file" ref={fileRef1} style={{ display: 'none' }} onChange={uploadPhoto1} multiple accept=".jpg, .png" />
          </div>
        )
      }
    </View>
  )

}
