import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput } from 'react-native-web';
import { themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.1, textAlign: 'left', headerTitle: 'ID' },
  { id: 2, field1: 'feedPhoto1', flex: 0.4, textAlign: 'left', headerTitle: 'รูป' },
  { id: 3, field1: 'feedScore', flex: 0.4, textAlign: 'right', headerTitle: 'Sort Score' },
];

const DETAIL_DEFINE = [
  {
    id: 1, fieldName: 'id', displayName: 'ID', newValue: 0, controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 2, fieldName: 'feedScore', displayName: 'Sort Score', newValue: 0 },
  { id: 3, fieldName: 'feedLink', displayName: 'Link', newValue: '' },
  { id: 4, fieldName: 'feedPhoto1', displayName: 'รูป', newValue: '', controlType: 'photoupload', controlOption: { width: 200, height: 80 } },
];

const QUERY_NAME = 'feeds';
const QUERY_CON = { feedType: 'ContentBanner' };
const NEW_VALUE = { feedType: 'ContentBanner' };

export const ContentBanner = () => {
  const [selectIndex, setSelectIndex] = useState(-1);

  const beforeSavePress = async (data) => {
    return { data: { ...data, feedPublic: 1 } }
  }
  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={12} style={{ height: '100vh', padding: 5 }}>
        <ViewList onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE} queryName={QUERY_NAME} queryCon={QUERY_CON} />
      </Col>
      <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail
          selectIndex={selectIndex} detailDefine={DETAIL_DEFINE} queryName={QUERY_NAME} 
          newValue={NEW_VALUE} onSavePress={(id) => setSelectIndex(-1)}
          beforeSavePress={beforeSavePress}
        />
      </Col>
    </Row>
  )
}

