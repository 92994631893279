import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput } from 'react-native-web';
import { themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.1, textAlign: 'left', headerTitle: 'ID' },
  { id: 2, field1: 'title', flex: 0.4, textAlign: 'left', headerTitle: 'เรื่อง' },
  { id: 3, field1: 'startDate', flex: 0.2, textAlign: 'right', headerTitle: 'วันเวลาที่จะส่ง', controlType: 'datetime' },
  { id: 4, field1: 'endDate', flex: 0.2, textAlign: 'right', headerTitle: 'วันเวลาส่งจริง', controlType: 'datetime' },
];

const DETAIL_DEFINE = [
  {
    id: 1, fieldName: 'id', displayName: 'ID', newValue: 0, controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 2, fieldName: 'title', displayName: 'title', newValue: '' },
  { id: 3, fieldName: 'content', displayName: 'Content', newValue: '', controlOption: { textInputStyle: { height: 200, paddingVertical: 5 }, textInputProp: { multiline: true } } },
  {
    id: 5, fieldName: 'startDate', displayName: 'วันเวลาส่ง', newValue: new Date(), controlType: 'datetimeinput',
    // controlOption: { customDefine: 'now_or_custom_datetime' } 
  },
  { id: 9, fieldName: 'feedPhoto1', displayName: 'รูป', newValue: '', controlType: 'photoupload', controlOption: { width: 200, height: 80 } },
];

const QUERY_NAME = 'feeds';
const QUERY_CON = { feedType: 'PromoMessage' };
const NEW_VALUE = { feedType: 'PromoMessage' };

export const Noti = () => {
  const [selectIndex, setSelectIndex] = useState(-1);
  const [detailDefine, setDetailDefine] = useState(DETAIL_DEFINE);

  useEffect(() => {
    setDetailDefine(prev => {
      const tmp = prev.map(item2 => {
        if (item2.id != 5) {
          return item2
        } else {
          return {
            ...item2,
            newValue: new Date()
          }
        }
      })
      return tmp;
    });
  }, [selectIndex])

  const beforeSavePress = async (data) => {
    return { data, errorList: validateData(data) }
  }



  const validateData = (data) => {
    let tmpErrorList = [];
    for (const item of ['title', 'content']) {
      if (!(data?.[item])) {
        tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้' }]
      }
    }
    return tmpErrorList
  }

  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={12} style={{ height: '100vh', padding: 5 }}>
        <ViewList onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE} queryName={QUERY_NAME} queryCon={QUERY_CON} />
      </Col>
      <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail
          selectIndex={selectIndex} detailDefine={detailDefine} queryName={QUERY_NAME} newValue={NEW_VALUE}
          onSavePress={(id) => setSelectIndex(-1)}
          beforeSavePress={beforeSavePress}
        />
      </Col>
    </Row>
  )
}

