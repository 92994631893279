import React,{ useContext, useState,useEffect } from "react";
import { store } from "../store";
import { useNavigate } from "react-router-dom";
import { appLogout } from "../component/custom";
import { TextInput,Text } from 'react-native-web';
import { format, parse,isValid } from 'date-fns'


export const Page4 = () => {
  const { globalState, dispatch } = useContext(store)
  const navigate = useNavigate()
  const [text, setText] = useState(format(new Date(), 'dd/MM/yyyy HH:mm'));
  const [dateTime,setDateTime] = useState();

  useEffect(() => {
    const tmpDateTime = parse(text, 'dd/MM/yyyy HH:mm', new Date());
    console.log('tmpDateTime', tmpDateTime);
    if (isValid(tmpDateTime) && text.length==16) {
      setDateTime(tmpDateTime)
    }
  }, [text])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingTop: 20 }}>
      <div>Page4</div>
      <TextInput
        style={{ width: 200, height: 40, backgroundColor: 'white' }}
        value={text}
        onChangeText={text => setText(prev => getDateTimeOutput(prev, text))}
      />
      <Text>{JSON.stringify(dateTime)}</Text>
    </div>
  )
}

function getDateTimeOutput(prev, text) {
  let tmpString = text.split('');
  if (tmpString.length > 16) return prev;
  console.log('length:' + tmpString.length + ':' + text.length);

  try {
    if (prev?.length < text?.length) {
      if (text.length == 2) {
        tmpString.splice(2, 0, '/')
      } else if (text.length >= 2 && tmpString.length >= 3 && tmpString[2] != '/') {
        tmpString.splice(2, 0, '/')
      } else if (text.length == 5) {
        tmpString.splice(5, 0, '/')
      } else if (text.length >= 4 && tmpString.length >= 6 && tmpString[5] != '/') {
        tmpString.splice(5, 0, '/')
      } else if (text.length == 10) {
        tmpString.splice(10, 0, ' ')
      } else if (text.length >= 9 && tmpString.length >= 10 && tmpString[10] != ' ') {
        tmpString.splice(10, 0, ' ')
      } else if (text.length >= 13 && tmpString[13] != ':') {
        tmpString.splice(13, 0, ':')
      }
    } else if (prev?.length > text?.length) {
      if (text.length == 3) {
        tmpString.splice(2, 1);
      }
      if (text.length == 6) {
        tmpString.splice(5, 1);
      }
      if (text.length == 11) {
        tmpString.splice(10, 1);
      }
      if (text.length == 14) {
        tmpString.splice(13, 1);
      }
    }
  } catch (e) {
    return prev;
  }
  return tmpString.join('');
}
