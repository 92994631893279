import React, { createContext, useReducer } from 'react';
import { server_var } from "../src/config/servervar"
import { Platform } from 'react-native';
import { getSocketClient } from "../src/component/custom";
const { socket, client } = getSocketClient();

const initialState = {
  client,
  socket,
  user: null,
  //   totalUnread: { u2u: 0, s2u: 0, u2s: 0, queline: 0, noti: 0 },
  //   toast: { visible: false, text: '' },
  //   initInfo: { appVersion: 0, termVersion: 0, consentVersion: 0 },
  //   deferNoti: null,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer((globalState, action) => {
    var newState = {};
    switch (action.type) {
      case 'setUser':
        newState = {
          ...globalState,
          user: action.payload,
        };
        return newState;
      case 'changeUserPhoto':
        newState = {
          ...globalState,
          user: { ...globalState.user, avatar: action.payload },
        };
        return newState;
      case 'showToast':
        newState = {
          ...globalState,
          toast: {
            visible: true,
            text: action.payload.text,
            timeout: action.payload.timeout ? action.payload.timeout : 3000,
          },
        };
        return newState;
      case 'hideToast':
        newState = {
          ...globalState,
          toast: { visible: false },
        };
        return newState;
      default:
        throw new Error();
    }
  }, initialState);
  return <Provider value={{ globalState, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
