import React, { useContext, useState, useEffect, useRef } from "react";
import io from 'socket.io-client'
import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import authentication from '@feathersjs/authentication-client'
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput, Image, Platform, Modal, Dimensions } from 'react-native-web';
import { themeColor, server_var } from "../config/servervar";
import EventEmitter from "./EventEmitter";
import { uploadPhoto } from './custom';
import { CloseOutlined, PlusCircleOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { parse, format, parseISO, isValid, formatISO } from 'date-fns';
import { Dropdown, Space } from 'antd';
import { DownOutlined, CheckCircleOutlined, BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { useDebounce } from "./custom";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

export function TopControlList({ viewListOption, onPress, searchList, setSearchList }) {
  let topControlList = [];
  if (!(viewListOption?.hideNew)) {
    topControlList = [{ id: 1, title: 'New', type: 'button', onPress: () => onPress(0) }];
  }
  topControlList = [...(viewListOption?.topControlList ?? []), ...topControlList];

  return (
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {topControlList.filter(item2 => ['search', 'multi_column_search', 'date', 'daterange', 'dropdown'].includes(item2.type)).map(item2 =>
          <ControlListItem key={item2.id.toString()} item={item2} searchList={searchList} setSearchList={setSearchList} />
        )}
      </View>
      {topControlList.filter(item2 => ['search', 'multi_column_search', 'date', 'daterange', 'dropdown'].includes(item2.type)).length == 0 && <Text></Text>}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {topControlList.filter(item2 => item2.type == 'button').map(item2 => (
          <ControlListItem key={item2.id.toString()} item={item2} />
        ))}
      </View>
    </View>
  )
}

function ControlListItem(props) {

  const { item } = props;
  if (item.type == 'button') {
    return <ControlListItemButton {...props} />
  }
  if (item.type == 'multi_column_search') {
    return <ControlListItemMultiSearch {...props} />
  }
  if (item.type == 'date') {
    return <ControlListItemDate {...props} />
  }
  if (item.type == 'daterange') {
    return <ControlListItemDateRange {...props} />
  }
  if (item.type == 'dropdown') {
    return <ControlListItemDropDown {...props} />
  }
}

function ControlListItemButton({ item }) {
  return (
    <Pressable key={item.id.toString()} onPress={() => item.onPress()}>
      {({ hovered }) => (
        <View style={{
          width: 80, height: 30, backgroundColor: themeColor.color1, marginHorizontal: 5,
          borderRadius: 10, justifyContent: 'center', alignItems: 'center',
          opacity: hovered ? 0.8 : 1,
        }}>
          <Text style={{ color: 'white' }}>{item.title}</Text>
        </View>
      )}
    </Pressable>
  )
}

function ControlListItemDropDown({ item, setSearchList, searchList }) {
  const [curKey, setCurKey] = useState(0);
  const [ddList, setDdList] = useState([]);

  const onClick = ({ key }) => {
    setCurKey(key)
    setSearchList(prev => {
      const tmp = [...prev].filter(item2 => Object.keys(item2) != item.searchField);
      if (key == 0) {
        return tmp
      } else {
        return [...tmp, { [item.searchField]: key }]
      }
    })
  }

  useEffect(() => {
    setDdList([{ key: 0, label: 'ทั้งหมด' }, ...item.list])
  }, [item.list])

  return (
    <Dropdown menu={{ items: ddList, onClick }} trigger={['click']}>
      <a onClick={(e) => e.preventDefault()}>
        <View>
          <View style={{
            backgroundColor: 'white', flexDirection: 'row',
            height: 30,
            width: 150,
            borderRadius: 5, paddingHorizontal: 5, alignItems: 'center', justifyContent: 'space-between'
          }}>
            <Text>{ddList.find(item5 => item5.key == curKey)?.label}</Text>
            <CaretDownOutlined />
          </View>
        </View>
      </a>
    </Dropdown>
  )
}

function ControlListItemMultiSearch({ item, setSearchList, searchList }) {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);

  useEffect(() => {
    const tmpSearchQuery1 = item.searchField;
    const tmpSearchQuery2 = { key: item.id, $or: tmpSearchQuery1?.map(item2 => ({ [item2]: { $like: `%${searchText}%` } })) }
    setSearchList(prev => {
      const tmp = [...prev].filter(item2 => item2.key != item.id)
      return [...tmp, tmpSearchQuery2]
    })
  }, [debouncedSearchTerm])

  return (

    <TextInput
      style={[{
        width: 200, height: 35, backgroundColor: 'white', borderWidth: 1, borderColor: 'lightgray',
        paddingHorizontal: 10, borderRadius: 5, marginHorizontal: 10
      }, item.textInputStyle]}
      placeholder={item.placeholder ?? "ค้นหา"}
      placeholderTextColor="gray"
      value={searchText}
      onChangeText={text => setSearchText(text)}
    />
  )
}

function ControlListItemDate({ item, setSearchList, searchList }) {

  const onChange = (date, dateString) => {
    let tmpSearchQuery2;
    if (dateString) {
      tmpSearchQuery2 = { key: item.id, [item.searchField]: { [item.searchOperator]: dateString } }
    }
    setSearchList(prev => {
      const tmp = [...prev].filter(item2 => item2.key != item.id)
      if (tmpSearchQuery2) {
        return [...tmp, tmpSearchQuery2]
      }
      return [...tmp]
    })
  }

  return (
    <View style={{ flexDirection: 'row', marginHorizontal: 10 }}>
      <DatePicker onChange={onChange} placeholder={item.placeholder} />
    </View>
  )
}

function ControlListItemDateRange({ item, setSearchList, searchList }) {

  const onChange = (date, dateString) => {
    console.log('dateString', dateString);
    let tmpSearchQuery2;
    if (dateString) {
      tmpSearchQuery2 = { key: item.id, [item.searchField]: { $gt: dateString[0], $lt: dateString[1] } }
    }
    setSearchList(prev => {
      const tmp = [...prev].filter(item2 => item2.key != item.id)
      if (dateString[0] && dateString[1]) {
        return [...tmp, tmpSearchQuery2]
      }
      return [...tmp]
    })
  }

  return (
    <View style={{ flexDirection: 'row', marginHorizontal: 10 }}>
      <RangePicker onChange={onChange}
        placeholder={item.placeholder}
      />
    </View>
  )
}