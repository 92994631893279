import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput } from 'react-native-web';
import { themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.1, textAlign: 'left', headerTitle: 'ID' },
  { id: 2, field1: 'mobileNumber', flex: 0.2, textAlign: 'left', headerTitle: 'UserName' },
  { id: 3, field1: 'displayName', flex: 0.6, textAlign: 'left', headerTitle: 'ผู้ใช้งาน' },
  { id: 4, field1: 'roles', flex: 0.2, textAlign: 'left', headerTitle: 'role' },
];

const DETAIL_DEFINE = [
  {
    id: 1, fieldName: 'id', displayName: 'ID', newValue: 0, controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 2, fieldName: 'mobileNumber', displayName: 'UserName', newValue: '' },
  { id: 3, fieldName: 'firstName', displayName: 'ชื่อ', newValue: '' },
  { id: 4, fieldName: 'lastName', displayName: 'นามสกุล', newValue: '' },
  { id: 5, fieldName: 'password', displayName: 'รหัสผ่าน', newValue: '', controlType: 'password' },
  {
    id: 6, fieldName: 'roles', displayName: 'role', newValue: '', controlType: 'dropdown', controlOption: {
      list: [{ id: 1, title: '', value: '' }, { id: 2, title: 'admin', value: 'admin' }, { id: 3, title: 'cashier', value: 'cashier' }, { id: 4, title: 'marketing', value: 'marketing' }]
    }
  },
  // { id: 7, fieldName: 'shopId', displayName: 'shopId', newValue: '' },
  {
    id: 7, fieldName: 'shopId', displayName: 'หมายเลขร้าน', newValue: 1, controlType: 'dropdown',
    controlOption: {
      visible: 'code02',
      list: []
    }
  },

];

const QUERY_NAME = 'users';
const QUERY_CON = { staffFlag: 1 };
const NEW_VALUE = { staffFlag: 1 };

export const User = () => {
  const [selectIndex, setSelectIndex] = useState(-1);
  const [detailDefine, setDetailDefine] = useState(DETAIL_DEFINE);
  const { globalState } = useContext(store);


  useEffect(() => {
    loadShopDropDown();
  }, [])

  const loadShopDropDown = async () => {
    const res = await globalState.client.service('shops').find({ query: { $limit: 100 } });
    console.log('res', res);
    setDetailDefine(prev => {
      const tmp = prev.map(item2 => {
        if (item2.id != 7) {
          return item2
        } else {
          return {
            ...item2,
            controlOption: {
              ...item2.controlOption,
              list: res.data.map(item3 => ({ id: item3.id, title: item3.shopName, value: item3.id }))
            }
          }
        }
      })
      return tmp;
    })
  }

  const beforeSavePress = async (data) => {
    return { data: { ...data, ['$ignoreAddQue']: 1 }, errorList: validateData(data) }
  }

  const validateData = (data) => {
    let tmpErrorList = [];
    for (const item of ['mobileNumber', 'roles', 'shopId', 'firstName', 'lastName']) {
      if (!(data?.[item])) {
        tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้' }]
      }
    }
    console.log('point1', data?.['password'] != undefined);
    console.log('point2', !(data?.['password']));
    if (data?.['password'] != undefined && !(data?.['password'])) {
      console.log('point3');
      tmpErrorList = [...tmpErrorList, { fieldName: 'password', errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้' }]
    }
    return tmpErrorList
  }

  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={12} style={{ height: '100vh', padding: 5 }}>
        <ViewList onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE} queryName={QUERY_NAME} queryCon={QUERY_CON} />
      </Col>
      <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail
          selectIndex={selectIndex} detailDefine={detailDefine} queryName={QUERY_NAME} newValue={NEW_VALUE}
          onSavePress={(id) => setSelectIndex(-1)}
          beforeSavePress={beforeSavePress}
        />
      </Col>
    </Row>
  )
}

