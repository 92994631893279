import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput } from 'react-native-web';
import { themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';
import { COUPON_TYPE_LIST } from "../config/servervar";

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.1, textAlign: 'left', headerTitle: 'ID', },
  { id: 2, field1: 'title', flex: 0.4, textAlign: 'left', headerTitle: 'ชื่อ' },
  {
    id: 3, field1: 'couponType', flex: 0.2, textAlign: 'left', headerTitle: 'ประเภท', controlType: 'mapping',
    controlOption: { list: COUPON_TYPE_LIST.map(item5 => ({ ...item5, value: item5.couponType })) }
  },
  { id: 4, field1: 'couponValue', flex: 0.2, textAlign: 'right', headerTitle: 'มูลค่า' },
  { id: 5, field1: 'pointToGet', flex: 0.2, textAlign: 'right', headerTitle: 'แต้มที่แลก' },
];

const DETAIL_DEFINE = [
  {
    id: 1, fieldName: 'id', displayName: 'ID', newValue: 0, controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 2, fieldName: 'title', displayName: 'ชื่อคูปอง', newValue: '' },
  {
    id: 3, fieldName: 'couponType', displayName: 'ประเภทคูปอง', newValue: '', controlType: 'dropdown', controlOption: {
      list: COUPON_TYPE_LIST.map(item5 => ({ ...item5, value: item5.couponType }))
    }
  },
  {
    id: 5, fieldName: 'shopId', displayName: 'หมายเลขร้าน', newValue: 1, controlType: 'dropdown', controlOption: {
      visible: 'code01', list: []
    }
  },
  {
    id: 6, fieldName: 'fakeProductCode', displayName: 'รหัสขายสินค้า', newValue: '', controlOption: {
      visible: 'code03'
    }
  },
  {
    id: 7, fieldName: 'limitAmount', displayName: 'จำกัดจำนวน', newValue: 0, controlOption: {
      visible: 'code03'
    }
  },
  { id: 8, fieldName: 'couponValue', displayName: 'มูลค่าส่วนลด', newValue: 0 },
  { id: 9, fieldName: 'pointToGet', displayName: 'แต้มที่ต้องใช้แลก', newValue: 0 },
  { id: 10, fieldName: 'startDate', displayName: 'วัน/เวลาเริ่มต้น', newValue: '', controlType: 'datetimeinput' },
  { id: 11, fieldName: 'endDate', displayName: 'วัน/เวลาสิ้่นสุด', newValue: '', controlType: 'datetimeinput' },
  {
    id: 12, fieldName: 'comment', displayName: 'รายละเอียด', newValue: '',
    controlOption: { textInputStyle: { height: 200, paddingVertical: 5 }, textInputProp: { multiline: true } }
  },
  { id: 13, fieldName: 'photo1', displayName: 'รูป', newValue: '', controlType: 'photoupload', },
  { id: 14, fieldName: 'conWhenBuy', displayName: '', newValue: '', hide: true },
  { id: 15, fieldName: 'conWhenShow', displayName: '', newValue: '', hide: true },
  { id: 16, fieldName: 'conWhenScan', displayName: '', newValue: '', hide: true },
  { id: 17, fieldName: 'sortValue', displayName: '', newValue: 0, hide: true },
  { id: 18, fieldName: 'archiveFlag', displayName: '', newValue: 0, hide: true },
];

const QUERY_NAME = 'coupons';
const QUERY_CON = {};

export const Coupon = () => {
  const [selectIndex, setSelectIndex] = useState(-1);
  const { globalState, dispatch } = useContext(store)
  const [detailDefine, setDetailDefine] = useState(DETAIL_DEFINE);

  useEffect(() => {
    loadShopDropDown();
  }, [])

  const loadShopDropDown = async () => {
    const res = await globalState.client.service('shops').find({ query: { $limit: 100 } });
    setDetailDefine(prev => {
      const tmp = prev.map(item2 => {
        if (item2.id != 5) {
          return item2
        } else {
          return {
            ...item2,
            controlOption: {
              ...item2.controlOption,
              list: res.data.map(item3 => ({ id: item3.id, title: item3.shopName, value: item3.id }))
            }
          }
        }
      })
      return tmp;
    })
  }

  const beforeSavePress = async (data) => {
    let retData;
    if (data.couponType)
      switch (data.couponType) {
        case 'General':
          retData = { ...data, conWhenBuy: { conName: 'buy_1_coupon_in_1_day' }, conWhenScan: { conName: 'limit_1_general_coupon' }, conWhenShow: null };
          break;
        case 'NewShopOpen':
          retData = { ...data, conWhenBuy: null, conWhenScan: { conName: 'limit_shop', shopId: data.shopId }, conWhenShow: { conName: 'limit_1_time_buy' } };
          break;
        case 'DownloadApp':
          retData = { ...data, conWhenBuy: null, conWhenScan: null, conWhenShow: { conName: 'member_created_less_than_5_day_and_limit_1_time_buy' } };
          break;
        case 'Gimmick':
          console.log('case gimick');
          retData = { ...data, conWhenBuy: { conName: 'limit_amount' }, conWhenShow: { conName: 'limit_amount' } };
          break;
        case 'GenOneTime':
          retData = { ...data, conWhenBuy: null, conWhenScan: null, conWhenShow: { conName: 'limit_1_time_buy' } };
          break;
        default:
          retData = { ...data, conWhenBuy: null, conWhenScan: null, conWhenShow: null };
      }
    return { data: retData, errorList: validateData(data) }
  }

  const validateData = (data) => {
    let tmpErrorList = [];
    for (const item of ['title', 'couponType']) {
      if (!(data?.[item])) {
        tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้' }]
      }
    }
    for (const item of ['startDate', 'endDate']) {
      if (!(data?.[item])) {
        tmpErrorList = [...tmpErrorList, { fieldName: item, errorFlag: true, errorMsg: 'ไม่สามารถเว้นไว้ได้ หรือวันเวลาไม่ถูกต้อง' }]
      }
    }
    return tmpErrorList
  }

  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={12} style={{ height: '100vh', padding: 5 }}>
        <ViewList
          onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE} queryName={QUERY_NAME} queryCon={QUERY_CON}
          viewListOption={{ topControlList: [{ id: 1, title: 'Search', type: 'multi_column_search', searchField: ['title'] }] }}
        />
      </Col>
      <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail
          selectIndex={selectIndex} detailDefine={detailDefine} queryName={QUERY_NAME}
          onSavePress={(id) => setSelectIndex(-1)}
          beforeSavePress={beforeSavePress}
        />
      </Col>
    </Row>
  )
}

