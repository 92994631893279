export var server_var = {
  status: 'Dev',
  // server_api: 'http://testapi2.local:3030',
  server_api: 'https://apinamping01.orbitxstudio.com',
  version: '0.1 ',
  builtno: '1',
};

export const themeColor = {
  color1: "#159d49",
  color2: "#110b79",
  color3: '#169d48',
  color4: '#EA6762', //dark red
  // color3: '#98BF64',
  // color3: '#607D3B',
  // color3: '#3CB043',
  // color3: '#A1CD44'

  // color0: '#f0f2f5',
  // color1: "#7691e6",
  // color2: "#3D4C7C",
  // color3: "#89cff0",

  // color2: "#376a95",
  // color3: '#d8eefb',
  // color4: '#E0F6F0',
  // color5: '#FDEFD8',

  // color7: '#1C57A8', //new blue
  // color8: '#F2F2F2', //very pale gray ,, default screen
  // color9: '#76C69C', //pale green
  // color10: '#A1CD44', //green
  // color11: '#efefef', //light gre
  // color12: '#d3d3d3', //light grey 2
};

export const COUPON_TYPE_LIST = [
  { id: 1, couponType: 'General', title: 'แลกแต้ม' },
  { id: 2, couponType: 'SpecificBrand', title: 'เฉพาะ Brand' },
  { id: 3, couponType: 'NewShopOpen', title: 'เปิดร้านใหม่' },
  { id: 4, couponType: 'DownloadApp', title: 'ใช้ครั้งแรก' },
  { id: 5, couponType: 'SpecificProduct', title: 'เฉพาะสินค้า' },
  { id: 6, couponType: 'Gimmick', title: 'แลกซื้อสินค้า' },
  { id: 7, couponType: 'GenOneTime', title: 'แลกแต้มครั้งเดียว' },
]


