import React, { useContext } from "react";
import { store } from "../store";
import { appLogin } from "../component/custom";
import { Navigate } from "react-router-dom";
import { Text, TextInput, TouchableOpacity, View } from 'react-native-web';
import { useState } from "react";
import { theme } from "antd";
import { themeColor } from "../config/servervar";

export const LoginPage = () => {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({ mobileNumber: '', password: '' })
  const SCREEN_LIST = [
    {
      id: 1, type: 'textinput', title: 'Username', value: data['mobileNumber'],
      onChangeText: (text) => setData(prev => ({ ...prev, mobileNumber: text }))
    },
    {
      id: 2, type: 'textinput', title: 'Password', value: data['password'],
      onChangeText: (text) => setData(prev => ({ ...prev, password: text })),
      textInputProp: { secureTextEntry: true }
    },
    {
      id: 3, type: 'button', title: 'Login', onPress: async () => {
        try {
          const res = await appLogin({
            strategy: "local",
            mobileNumber: data?.mobileNumber,
            password: data?.password
          }, globalState, dispatch);
          // alert("Hello" + JSON.stringify(res.user));
        } catch (e) {
          alert("error" + JSON.stringify(e))
        }
      }
    }
  ]
  if (globalState?.user?.id) {
    return <Navigate to="/" replace />
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'lightgrey' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 30 }}>
          <img src="/namping_logo.png" style={{ width: 80, height: 80 }} />
          <h2 style={{ marginLeft: 20 }}>Namping Admin</h2>
        </div>
        <div style={{
          width: '500px', height: '500px', backgroundColor: 'white', display: 'flex', flexDirection: 'column',
          justifyContent: 'flex-start', alignItems: 'center', borderRadius: 10
        }}>
          <h2>Sign in your account</h2>
          {SCREEN_LIST.map(item => (
            <LoginComponent key={item.id.toString()} item={item} />
          ))}

        </div>
      </div>
    )
  }
}

function LoginComponent({ item }) {
  if (item.type == 'textinput') {
    return (
      <View style={{ marginTop: 20 }}>
        <Text>{item.title}</Text>
        <TextInput
          style={{
            width: 350, height: 40, backgroundColor: 'white', borderWidth: 1, borderColor: 'gray', marginTop: 10,
            borderRadius: 5, paddingHorizontal: 5,
          }}
          value={item.value} onChangeText={item.onChangeText}
          {...item?.textInputProp}
        />
      </View>
    )
  }
  if (item.type == 'button') {
    return (
      <TouchableOpacity
        style={{
          marginTop: 40, width: 350, height: 40, borderRadius: 5, borderColor: themeColor.color3, backgroundColor: themeColor.color3, justifyContent: 'center', alignItems: 'center'
        }}
        onPress={() => item.onPress()}
      >
        <Text style={{ color: 'white' }}>{item.title}</Text>
      </TouchableOpacity>
    )
  }
}