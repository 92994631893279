import React, { useContext, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from 'antd';
import { View, Text } from 'react-native-web'
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Routes, Route } from "react-router-dom";
import { HomePage } from './pages/Home';
import { LoginPage } from './pages/Login';
import { ScreenLayout } from './component/ScreenLayout';
import { Page1 } from './pages/Page1';
import { Page2 } from './pages/Page2';
import { Page3 } from './pages/Page3';
import { appReLogin, useCommon } from './component/custom';
import { store } from './store';
import { Coupon } from './pages/Coupon';
import { TopBanner } from './pages/TopBanner';
import { ContentBanner } from './pages/ContentBanner';
import { FlashSale } from './pages/FlashSale';
import { Shop } from './pages/Shop';
import { User } from './pages/User';
import { Noti } from './pages/Noti';
import { TrackCoupon } from './pages/TrackCoupon';
import { Customer } from './pages/Customer';
import { Page4 } from './pages/Page4';
import { Page5 } from './pages/Page5';


export default function App() {
  const { globalState, dispatch } = useContext(store)

  useEffect(() => {
    appReLogin(globalState, dispatch);
  }, [globalState.client])

  return (
    <Routes>
      <Route path="/" element={<ScreenLayout />}>
        <Route path="/" element={<Customer />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/trackcoupon" element={<TrackCoupon />} />
        <Route path="/topbanner" element={<TopBanner />} />
        <Route path="/contentbanner" element={<ContentBanner />} />
        <Route path="/flashsale" element={<FlashSale />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/notification" element={<Noti />} />
        <Route path="/user" element={<User />} />
        <Route path="/page4" element={<Page4 />} />
        <Route path="/page5" element={<Page5 />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}

