import React from "react";
import { server_var } from "../config/servervar";
import io from 'socket.io-client'
import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import authentication from '@feathersjs/authentication-client';
import FormData from 'form-data';
import axios from 'axios';
import { useState, useEffect } from "react";

export function getSocketClient() {
  const socket = io(server_var.server_api, {
    transports: ['websocket'],
    forceNew: true,
  });
  const client = feathers();
  client.configure(socketio(socket));
  client.configure(authentication({ storage: window.localStorage }));
  return { client, socket };
}

export async function appLogin(loginInfo, globalState, dispatch) {
  let res;
  try {
    console.log('poin1' + JSON.stringify(loginInfo));
    res = await globalState.client.authenticate(loginInfo);
    console.log('poin2');
    console.log('res', res.user);
    // if (res?.user?.roles != 'admin') {
    //   return null;
    // }
    dispatch({ type: 'setUser', payload: res.user })
    console.log('res', res);
    return res;
  } catch (e) {
    console.log('error', e)
    return null;
  }
}

export async function appReLogin(globalState, dispatch) {
  let res;
  try {
    res = await globalState.client.reAuthenticate();
    dispatch({ type: 'setUser', payload: res.user });
  } catch (e) { }
  return res;
}

export async function appLogout(globalState, dispatch) {
  globalState.client.logout();
  dispatch({ type: 'setUser', payload: null })
  return;
}

export function useCommon(globalState, dispatch) {
  appReLogin(globalState, dispatch);
  return;
}

export async function uploadPhoto(mode, multiple, photoType, targetFiles) {
  let retImage = [];
  // const jwtToken = await getJwtToken();

  let uploadTo = mode;
  if (mode == 'banner') {
    uploadTo = 'feed';
  }
  if (mode == 'avatar') {
    uploadTo = 'profile';
  }
  for (const item of targetFiles) {
    const form = new FormData();
    const headers = form.getHeaders ? form.getHeaders() : { 'Content-Type': 'multipart/form-data' };
    form.append("tmpFile", (item));
    let originalFileName = item.name;
    const config = { headers, params: { uploadTo, originalFileName } };
    const uploadResult = await axios.post(`${server_var.server_api}/uploadFile`, form, config);
    console.log('\n\n\nuploadResult2', uploadResult.data);
    const fileName = uploadResult?.data?.fileName;
    console.log('fileName:' + fileName);
    retImage.push({ filename: fileName });
  }
  return retImage;
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
}
