import React, { useContext, useState, useEffect } from "react";
import { store } from "../store";
import { Row, Col } from 'antd';
import { Text, View, FlatList, Pressable, ScrollView, TextInput } from 'react-native-web';
import { themeColor } from "../config/servervar";
import { ViewList, ViewDetail } from '../component/listdetail';

const LIST_DEFINE = [
  { id: 1, field1: 'id', flex: 0.1, textAlign: 'left', headerTitle: 'ID' },
  { id: 2, field1: 'shopCode', flex: 0.2, textAlign: 'left', headerTitle: 'รหัสร้าน' },
  { id: 3, field1: 'shopName', flex: 0.6, textAlign: 'left', headerTitle: 'ชื่อร้าน' },
  { id: 4, field1: 'shopTel', flex: 0.2, textAlign: 'left', headerTitle: 'เบอร์โทร' },
];

const DETAIL_DEFINE = [
  {
    id: 1, fieldName: 'id', displayName: 'ID', newValue: 0, controlOption: {
      textInputStyle: { backgroundColor: 'lightgray' }, textInputProp: { editable: false }
    }
  },
  { id: 2, fieldName: 'shopCode', displayName: 'รหัสร้าน', newValue: '' },
  { id: 3, fieldName: 'shopName', displayName: 'ชื่อร้าน', newValue: '' },
  { id: 4, fieldName: 'shopTel', displayName: 'เบอร์โทร', newValue: '' },
  { id: 5, fieldName: 'shopLineId', displayName: 'Line ID', newValue: '' },
  { id: 6, fieldName: 'shopLat', displayName: 'Latitude', newValue: '' },
  { id: 7, fieldName: 'shopLong', displayName: 'Longitude', newValue: '' },
];

const QUERY_NAME = 'shops';
const QUERY_CON = {};

export const Shop = () => {
  const [selectIndex, setSelectIndex] = useState(-1);
  return (
    <Row style={{ flex: 1, flexWrap: 'nowrap' }}>
      <Col span={12} style={{ height: '100vh', padding: 5 }}>
        <ViewList onPress={id => setSelectIndex(id)} listDefine={LIST_DEFINE} queryName={QUERY_NAME} queryCon={QUERY_CON} />
      </Col>
      <Col span={12} style={{
        height: '100vh', padding: 5,
        borderWidth: 1, borderLeftStyle: 'solid', borderColor: 'gray'
      }}>
        <ViewDetail selectIndex={selectIndex} detailDefine={DETAIL_DEFINE} queryName={QUERY_NAME} onSavePress={(id) => setSelectIndex(-1)}/>
      </Col>
    </Row>
  )
}

